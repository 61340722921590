import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import Rabail_Sajjad from '../../Assets/Rabail Sajjad.png'
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaSearch } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";

function Blog() {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedData, setSearchedData] = useState([]);
  const [searchStatus, setSearchStatus] = useState('');
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, [])

  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, [])
  useEffect(() => {
    AOS.init();
    axios.post('https://api.mywisdomhub.com/blogs/get_all_blogs')
      .then(response => {
        console.log(response)
        setBlogs(response.data);
      })
      .catch(error => {
        console.error('Error fetching blogs:', error);
      });
  }, []);

  const handleSearch = () => {
    setSearchStatus('searching');

    setTimeout(() => {
      console.log('Search term:', searchTerm);
      const filteredData = blogs.filter((blog) =>
        blog.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      console.log('Filtered data:', filteredData);

      if (filteredData.length === 0) {
        setSearchStatus('notFound');
      } else {
        setSearchedData(filteredData);
        setSearchStatus('found');
      }
      console.log('Search status:', searchStatus);
    }, 50);
  };

  const handleKeyPress = (e) => {
    if (e.key) {
      handleSearch();
    }
  };

  const handleButtonClick = () => {
    handleSearch();
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleLinkClick = () => {
    scroll.scrollToTop();
  }
  return (
    <>
      <div className="mb-16">
        <div className="px-6 md:px-8 py-10">
          <div className="flex justify-end mb-10">
            <div className="relative">
              <input
                type="text"
                placeholder="Search"
                className="px-4 py-2 border h-[40px] border-gray-300 rounded-l-md outline-none"
                value={searchTerm}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                onKeyDown={handleSearch}
              />
            </div>
            <div>
              <button
                className="px-4 h-[40px] bg-[#2DC3D0] hover:bg-[#2db8d0] rounded-r-md transition-all duration-300 ease-in-out ml-2"
                onClick={handleButtonClick}
              >
                <FaSearch className="text-white" />
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {searchStatus === 'notFound' ? (
              <div className=" absolute w-[97%] mt-8 flex justify-center items-center font-semibold text-[20px] text-red-600  ">No results found</div>
            ) : (
              (searchTerm === '' ? blogs : searchedData).map((blog, key) => (
                <div className="w-full mb-4 md:mb-0" key={key} >
                  <div className="bg-white rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 ease-in-out overflow-hidden " data-aos="fade-up" onClick={handleLinkClick}>
                    <Link to={`/blog-page?id=${blog.id}`}>
                      <div className="p-4">
                        <Link to={`/blog-page?id=${blog.id}`}>
                          <h2 className="text-2xl font-bold mb-2 hover:underline">{blog.name}</h2>
                        </Link>
                        <div className="flex items-center mb-2 text-gray-600">
                          <img src={Rabail_Sajjad} alt="Rabail Sajjad" className="w-8 h-8 rounded-full mr-3 object-cover" />
                          <div>
                            <span>Rabail Sajjad</span>
                            <span className="text-gray-500 ml-1">-</span>
                            <span className="text-gray-500 ml-1">{blog.date}</span>
                          </div>
                        </div>
                        <p className="text-gray-600">{blog.description}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;